// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "organizations_service.proto" (package "organizations_service", syntax proto3)
// tslint:disable
//
// Protobuf definition file
//
import { ServiceType } from '@protobuf-ts/runtime-rpc'
import { WireType } from '@protobuf-ts/runtime'
import type { BinaryWriteOptions } from '@protobuf-ts/runtime'
import type { IBinaryWriter } from '@protobuf-ts/runtime'
import { UnknownFieldHandler } from '@protobuf-ts/runtime'
import type { BinaryReadOptions } from '@protobuf-ts/runtime'
import type { IBinaryReader } from '@protobuf-ts/runtime'
import type { PartialMessage } from '@protobuf-ts/runtime'
import { reflectionMergePartial } from '@protobuf-ts/runtime'
import { MessageType } from '@protobuf-ts/runtime'
/**
 * @generated from protobuf message organizations_service.Empty
 */
export interface Empty {}
/**
 * @generated from protobuf message organizations_service.OrganizationId
 */
export interface OrganizationId {
  /**
   * @generated from protobuf field: int32 organizationId = 1;
   */
  organizationId: number
}
/**
 * @generated from protobuf message organizations_service.OrganizationInfo
 */
export interface OrganizationInfo {
  /**
   * @generated from protobuf field: string name = 1;
   */
  name: string
  /**
   * @generated from protobuf field: bytes picture_file_id = 2;
   */
  pictureFileId: Uint8Array
}
/**
 * @generated from protobuf message organizations_service.GetOrganizationInfoResponse
 */
export interface GetOrganizationInfoResponse {
  /**
   * @generated from protobuf field: organizations_service.OrganizationInfo organizationInfo = 1;
   */
  organizationInfo?: OrganizationInfo
  /**
   * @generated from protobuf field: bool isDeleted = 2;
   */
  isDeleted: boolean
}
/**
 * @generated from protobuf message organizations_service.Permissions
 */
export interface Permissions {
  /**
   * @generated from protobuf field: bool canCreateAndDelete = 1;
   */
  canCreateAndDelete: boolean
  /**
   * @generated from protobuf field: bool canUpdate = 2;
   */
  canUpdate: boolean
  /**
   * @generated from protobuf field: bool canView = 3;
   */
  canView: boolean
  /**
   * @generated from protobuf field: bool canManageAdmins = 4;
   */
  canManageAdmins: boolean
  /**
   * @generated from protobuf field: bool canGetCode = 5;
   */
  canGetCode: boolean
  /**
   * @generated from protobuf field: bool canCourseCreateAndDelete = 6;
   */
  canCourseCreateAndDelete: boolean
  /**
   * @generated from protobuf field: bool canManageVerificationRequests = 7;
   */
  canManageVerificationRequests: boolean
  /**
   * @generated from protobuf field: bool canManageNews = 8;
   */
  canManageNews: boolean
}
/**
 * @generated from protobuf message organizations_service.UpdateOrganizationInfoRequest
 */
export interface UpdateOrganizationInfoRequest {
  /**
   * @generated from protobuf field: int32 organizationId = 1;
   */
  organizationId: number
  /**
   * @generated from protobuf field: organizations_service.OrganizationInfo organizationInfo = 2;
   */
  organizationInfo?: OrganizationInfo
}
/**
 * @generated from protobuf message organizations_service.GetAllOrganizationsInfoResponse
 */
export interface GetAllOrganizationsInfoResponse {
  /**
   * @generated from protobuf field: repeated int32 organizationIds = 1;
   */
  organizationIds: number[]
  /**
   * @generated from protobuf field: repeated organizations_service.OrganizationInfo organizationInfos = 2;
   */
  organizationInfos: OrganizationInfo[]
}
/**
 * @generated from protobuf message organizations_service.GetAllOrganizationsPermissionsResponse
 */
export interface GetAllOrganizationsPermissionsResponse {
  /**
   * @generated from protobuf field: repeated int32 organizationIds = 1;
   */
  organizationIds: number[]
  /**
   * @generated from protobuf field: repeated organizations_service.Permissions permissions = 2;
   */
  permissions: Permissions[]
}
/**
 * @generated from protobuf message organizations_service.UpdateAdminRequest
 */
export interface UpdateAdminRequest {
  /**
   * @generated from protobuf field: int32 organizationId = 1;
   */
  organizationId: number
  /**
   * @generated from protobuf field: int32 userId = 2;
   */
  userId: number
}
/**
 * @generated from protobuf message organizations_service.IsValidResponse
 */
export interface IsValidResponse {
  /**
   * @generated from protobuf field: bool isValid = 1;
   */
  isValid: boolean
}
/**
 * @generated from protobuf message organizations_service.OrganizationCode
 */
export interface OrganizationCode {
  /**
   * @generated from protobuf field: string code = 1;
   */
  code: string
}
/**
 * @generated from protobuf message organizations_service.UsersResponse
 */
export interface UsersResponse {
  /**
   * @generated from protobuf field: repeated int32 userIds = 1;
   */
  userIds: number[]
}
/**
 * @generated from protobuf message organizations_service.Speciality
 */
export interface Speciality {
  /**
   * @generated from protobuf field: int32 specialityId = 1;
   */
  specialityId: number
  /**
   * @generated from protobuf field: string specialityName = 2;
   */
  specialityName: string
}
/**
 * @generated from protobuf message organizations_service.SpecialitiesResponse
 */
export interface SpecialitiesResponse {
  /**
   * @generated from protobuf field: repeated organizations_service.Speciality specialities = 1;
   */
  specialities: Speciality[]
}
/**
 * @generated from protobuf message organizations_service.AddSpecialityRequest
 */
export interface AddSpecialityRequest {
  /**
   * @generated from protobuf field: int32 organizationId = 1;
   */
  organizationId: number
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string
}
/**
 * @generated from protobuf message organizations_service.RemoveSpecialityRequest
 */
export interface RemoveSpecialityRequest {
  /**
   * @generated from protobuf field: int32 organizationId = 1;
   */
  organizationId: number
  /**
   * @generated from protobuf field: int32 specialityId = 2;
   */
  specialityId: number
}
/**
 * @generated from protobuf message organizations_service.UpdateSpecialityRequest
 */
export interface UpdateSpecialityRequest {
  /**
   * @generated from protobuf field: int32 organizationId = 1;
   */
  organizationId: number
  /**
   * @generated from protobuf field: int32 specialityId = 2;
   */
  specialityId: number
  /**
   * @generated from protobuf field: string name = 3;
   */
  name: string
}
/**
 * @generated from protobuf message organizations_service.SpecialityId
 */
export interface SpecialityId {
  /**
   * @generated from protobuf field: int32 specialityId = 1;
   */
  specialityId: number
}
// @generated message type with reflection information, may provide speed optimized methods
class Empty$Type extends MessageType<Empty> {
  constructor() {
    super('organizations_service.Empty', [])
  }
  create(value?: PartialMessage<Empty>): Empty {
    const message = globalThis.Object.create(this.messagePrototype!)
    if (value !== undefined) reflectionMergePartial<Empty>(this, message, value)
    return message
  }
  internalBinaryRead(target?: Empty): Empty {
    return target ?? this.create()
  }
  internalBinaryWrite(message: Empty, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message organizations_service.Empty
 */
export const Empty = new Empty$Type()
// @generated message type with reflection information, may provide speed optimized methods
class OrganizationId$Type extends MessageType<OrganizationId> {
  constructor() {
    super('organizations_service.OrganizationId', [
      { no: 1, name: 'organizationId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<OrganizationId>): OrganizationId {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.organizationId = 0
    if (value !== undefined) reflectionMergePartial<OrganizationId>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: OrganizationId,
  ): OrganizationId {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 organizationId */ 1:
          message.organizationId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: OrganizationId, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 organizationId = 1; */
    if (message.organizationId !== 0) writer.tag(1, WireType.Varint).int32(message.organizationId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message organizations_service.OrganizationId
 */
export const OrganizationId = new OrganizationId$Type()
// @generated message type with reflection information, may provide speed optimized methods
class OrganizationInfo$Type extends MessageType<OrganizationInfo> {
  constructor() {
    super('organizations_service.OrganizationInfo', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'picture_file_id', kind: 'scalar', T: 12 /*ScalarType.BYTES*/ },
    ])
  }
  create(value?: PartialMessage<OrganizationInfo>): OrganizationInfo {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.name = ''
    message.pictureFileId = new Uint8Array(0)
    if (value !== undefined) reflectionMergePartial<OrganizationInfo>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: OrganizationInfo,
  ): OrganizationInfo {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* string name */ 1:
          message.name = reader.string()
          break
        case /* bytes picture_file_id */ 2:
          message.pictureFileId = reader.bytes()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: OrganizationInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* string name = 1; */
    if (message.name !== '') writer.tag(1, WireType.LengthDelimited).string(message.name)
    /* bytes picture_file_id = 2; */
    if (message.pictureFileId.length) writer.tag(2, WireType.LengthDelimited).bytes(message.pictureFileId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message organizations_service.OrganizationInfo
 */
export const OrganizationInfo = new OrganizationInfo$Type()
// @generated message type with reflection information, may provide speed optimized methods
class GetOrganizationInfoResponse$Type extends MessageType<GetOrganizationInfoResponse> {
  constructor() {
    super('organizations_service.GetOrganizationInfoResponse', [
      { no: 1, name: 'organizationInfo', kind: 'message', T: () => OrganizationInfo },
      { no: 2, name: 'isDeleted', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ])
  }
  create(value?: PartialMessage<GetOrganizationInfoResponse>): GetOrganizationInfoResponse {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.isDeleted = false
    if (value !== undefined) reflectionMergePartial<GetOrganizationInfoResponse>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetOrganizationInfoResponse,
  ): GetOrganizationInfoResponse {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* organizations_service.OrganizationInfo organizationInfo */ 1:
          message.organizationInfo = OrganizationInfo.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.organizationInfo,
          )
          break
        case /* bool isDeleted */ 2:
          message.isDeleted = reader.bool()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: GetOrganizationInfoResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* organizations_service.OrganizationInfo organizationInfo = 1; */
    if (message.organizationInfo)
      OrganizationInfo.internalBinaryWrite(
        message.organizationInfo,
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join()
    /* bool isDeleted = 2; */
    if (message.isDeleted !== false) writer.tag(2, WireType.Varint).bool(message.isDeleted)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message organizations_service.GetOrganizationInfoResponse
 */
export const GetOrganizationInfoResponse = new GetOrganizationInfoResponse$Type()
// @generated message type with reflection information, may provide speed optimized methods
class Permissions$Type extends MessageType<Permissions> {
  constructor() {
    super('organizations_service.Permissions', [
      { no: 1, name: 'canCreateAndDelete', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 2, name: 'canUpdate', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 3, name: 'canView', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 4, name: 'canManageAdmins', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 5, name: 'canGetCode', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 6, name: 'canCourseCreateAndDelete', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 7, name: 'canManageVerificationRequests', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 8, name: 'canManageNews', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ])
  }
  create(value?: PartialMessage<Permissions>): Permissions {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.canCreateAndDelete = false
    message.canUpdate = false
    message.canView = false
    message.canManageAdmins = false
    message.canGetCode = false
    message.canCourseCreateAndDelete = false
    message.canManageVerificationRequests = false
    message.canManageNews = false
    if (value !== undefined) reflectionMergePartial<Permissions>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: Permissions,
  ): Permissions {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* bool canCreateAndDelete */ 1:
          message.canCreateAndDelete = reader.bool()
          break
        case /* bool canUpdate */ 2:
          message.canUpdate = reader.bool()
          break
        case /* bool canView */ 3:
          message.canView = reader.bool()
          break
        case /* bool canManageAdmins */ 4:
          message.canManageAdmins = reader.bool()
          break
        case /* bool canGetCode */ 5:
          message.canGetCode = reader.bool()
          break
        case /* bool canCourseCreateAndDelete */ 6:
          message.canCourseCreateAndDelete = reader.bool()
          break
        case /* bool canManageVerificationRequests */ 7:
          message.canManageVerificationRequests = reader.bool()
          break
        case /* bool canManageNews */ 8:
          message.canManageNews = reader.bool()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: Permissions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* bool canCreateAndDelete = 1; */
    if (message.canCreateAndDelete !== false) writer.tag(1, WireType.Varint).bool(message.canCreateAndDelete)
    /* bool canUpdate = 2; */
    if (message.canUpdate !== false) writer.tag(2, WireType.Varint).bool(message.canUpdate)
    /* bool canView = 3; */
    if (message.canView !== false) writer.tag(3, WireType.Varint).bool(message.canView)
    /* bool canManageAdmins = 4; */
    if (message.canManageAdmins !== false) writer.tag(4, WireType.Varint).bool(message.canManageAdmins)
    /* bool canGetCode = 5; */
    if (message.canGetCode !== false) writer.tag(5, WireType.Varint).bool(message.canGetCode)
    /* bool canCourseCreateAndDelete = 6; */
    if (message.canCourseCreateAndDelete !== false)
      writer.tag(6, WireType.Varint).bool(message.canCourseCreateAndDelete)
    /* bool canManageVerificationRequests = 7; */
    if (message.canManageVerificationRequests !== false)
      writer.tag(7, WireType.Varint).bool(message.canManageVerificationRequests)
    /* bool canManageNews = 8; */
    if (message.canManageNews !== false) writer.tag(8, WireType.Varint).bool(message.canManageNews)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message organizations_service.Permissions
 */
export const Permissions = new Permissions$Type()
// @generated message type with reflection information, may provide speed optimized methods
class UpdateOrganizationInfoRequest$Type extends MessageType<UpdateOrganizationInfoRequest> {
  constructor() {
    super('organizations_service.UpdateOrganizationInfoRequest', [
      { no: 1, name: 'organizationId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'organizationInfo', kind: 'message', T: () => OrganizationInfo },
    ])
  }
  create(value?: PartialMessage<UpdateOrganizationInfoRequest>): UpdateOrganizationInfoRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.organizationId = 0
    if (value !== undefined) reflectionMergePartial<UpdateOrganizationInfoRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateOrganizationInfoRequest,
  ): UpdateOrganizationInfoRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 organizationId */ 1:
          message.organizationId = reader.int32()
          break
        case /* organizations_service.OrganizationInfo organizationInfo */ 2:
          message.organizationInfo = OrganizationInfo.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.organizationInfo,
          )
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: UpdateOrganizationInfoRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 organizationId = 1; */
    if (message.organizationId !== 0) writer.tag(1, WireType.Varint).int32(message.organizationId)
    /* organizations_service.OrganizationInfo organizationInfo = 2; */
    if (message.organizationInfo)
      OrganizationInfo.internalBinaryWrite(
        message.organizationInfo,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join()
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message organizations_service.UpdateOrganizationInfoRequest
 */
export const UpdateOrganizationInfoRequest = new UpdateOrganizationInfoRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class GetAllOrganizationsInfoResponse$Type extends MessageType<GetAllOrganizationsInfoResponse> {
  constructor() {
    super('organizations_service.GetAllOrganizationsInfoResponse', [
      { no: 1, name: 'organizationIds', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'organizationInfos', kind: 'message', repeat: 1 /*RepeatType.PACKED*/, T: () => OrganizationInfo },
    ])
  }
  create(value?: PartialMessage<GetAllOrganizationsInfoResponse>): GetAllOrganizationsInfoResponse {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.organizationIds = []
    message.organizationInfos = []
    if (value !== undefined) reflectionMergePartial<GetAllOrganizationsInfoResponse>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetAllOrganizationsInfoResponse,
  ): GetAllOrganizationsInfoResponse {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated int32 organizationIds */ 1:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.organizationIds.push(reader.int32())
          else message.organizationIds.push(reader.int32())
          break
        case /* repeated organizations_service.OrganizationInfo organizationInfos */ 2:
          message.organizationInfos.push(OrganizationInfo.internalBinaryRead(reader, reader.uint32(), options))
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: GetAllOrganizationsInfoResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated int32 organizationIds = 1; */
    if (message.organizationIds.length) {
      writer.tag(1, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.organizationIds.length; i++) writer.int32(message.organizationIds[i])
      writer.join()
    }
    /* repeated organizations_service.OrganizationInfo organizationInfos = 2; */
    for (let i = 0; i < message.organizationInfos.length; i++)
      OrganizationInfo.internalBinaryWrite(
        message.organizationInfos[i],
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join()
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message organizations_service.GetAllOrganizationsInfoResponse
 */
export const GetAllOrganizationsInfoResponse = new GetAllOrganizationsInfoResponse$Type()
// @generated message type with reflection information, may provide speed optimized methods
class GetAllOrganizationsPermissionsResponse$Type extends MessageType<GetAllOrganizationsPermissionsResponse> {
  constructor() {
    super('organizations_service.GetAllOrganizationsPermissionsResponse', [
      { no: 1, name: 'organizationIds', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'permissions', kind: 'message', repeat: 1 /*RepeatType.PACKED*/, T: () => Permissions },
    ])
  }
  create(value?: PartialMessage<GetAllOrganizationsPermissionsResponse>): GetAllOrganizationsPermissionsResponse {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.organizationIds = []
    message.permissions = []
    if (value !== undefined) reflectionMergePartial<GetAllOrganizationsPermissionsResponse>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetAllOrganizationsPermissionsResponse,
  ): GetAllOrganizationsPermissionsResponse {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated int32 organizationIds */ 1:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.organizationIds.push(reader.int32())
          else message.organizationIds.push(reader.int32())
          break
        case /* repeated organizations_service.Permissions permissions */ 2:
          message.permissions.push(Permissions.internalBinaryRead(reader, reader.uint32(), options))
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: GetAllOrganizationsPermissionsResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated int32 organizationIds = 1; */
    if (message.organizationIds.length) {
      writer.tag(1, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.organizationIds.length; i++) writer.int32(message.organizationIds[i])
      writer.join()
    }
    /* repeated organizations_service.Permissions permissions = 2; */
    for (let i = 0; i < message.permissions.length; i++)
      Permissions.internalBinaryWrite(
        message.permissions[i],
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join()
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message organizations_service.GetAllOrganizationsPermissionsResponse
 */
export const GetAllOrganizationsPermissionsResponse = new GetAllOrganizationsPermissionsResponse$Type()
// @generated message type with reflection information, may provide speed optimized methods
class UpdateAdminRequest$Type extends MessageType<UpdateAdminRequest> {
  constructor() {
    super('organizations_service.UpdateAdminRequest', [
      { no: 1, name: 'organizationId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'userId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<UpdateAdminRequest>): UpdateAdminRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.organizationId = 0
    message.userId = 0
    if (value !== undefined) reflectionMergePartial<UpdateAdminRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateAdminRequest,
  ): UpdateAdminRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 organizationId */ 1:
          message.organizationId = reader.int32()
          break
        case /* int32 userId */ 2:
          message.userId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: UpdateAdminRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 organizationId = 1; */
    if (message.organizationId !== 0) writer.tag(1, WireType.Varint).int32(message.organizationId)
    /* int32 userId = 2; */
    if (message.userId !== 0) writer.tag(2, WireType.Varint).int32(message.userId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message organizations_service.UpdateAdminRequest
 */
export const UpdateAdminRequest = new UpdateAdminRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class IsValidResponse$Type extends MessageType<IsValidResponse> {
  constructor() {
    super('organizations_service.IsValidResponse', [
      { no: 1, name: 'isValid', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ])
  }
  create(value?: PartialMessage<IsValidResponse>): IsValidResponse {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.isValid = false
    if (value !== undefined) reflectionMergePartial<IsValidResponse>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: IsValidResponse,
  ): IsValidResponse {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* bool isValid */ 1:
          message.isValid = reader.bool()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: IsValidResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* bool isValid = 1; */
    if (message.isValid !== false) writer.tag(1, WireType.Varint).bool(message.isValid)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message organizations_service.IsValidResponse
 */
export const IsValidResponse = new IsValidResponse$Type()
// @generated message type with reflection information, may provide speed optimized methods
class OrganizationCode$Type extends MessageType<OrganizationCode> {
  constructor() {
    super('organizations_service.OrganizationCode', [
      { no: 1, name: 'code', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ])
  }
  create(value?: PartialMessage<OrganizationCode>): OrganizationCode {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.code = ''
    if (value !== undefined) reflectionMergePartial<OrganizationCode>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: OrganizationCode,
  ): OrganizationCode {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* string code */ 1:
          message.code = reader.string()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: OrganizationCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* string code = 1; */
    if (message.code !== '') writer.tag(1, WireType.LengthDelimited).string(message.code)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message organizations_service.OrganizationCode
 */
export const OrganizationCode = new OrganizationCode$Type()
// @generated message type with reflection information, may provide speed optimized methods
class UsersResponse$Type extends MessageType<UsersResponse> {
  constructor() {
    super('organizations_service.UsersResponse', [
      { no: 1, name: 'userIds', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<UsersResponse>): UsersResponse {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.userIds = []
    if (value !== undefined) reflectionMergePartial<UsersResponse>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UsersResponse,
  ): UsersResponse {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated int32 userIds */ 1:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.userIds.push(reader.int32())
          else message.userIds.push(reader.int32())
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: UsersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* repeated int32 userIds = 1; */
    if (message.userIds.length) {
      writer.tag(1, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.userIds.length; i++) writer.int32(message.userIds[i])
      writer.join()
    }
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message organizations_service.UsersResponse
 */
export const UsersResponse = new UsersResponse$Type()
// @generated message type with reflection information, may provide speed optimized methods
class Speciality$Type extends MessageType<Speciality> {
  constructor() {
    super('organizations_service.Speciality', [
      { no: 1, name: 'specialityId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'specialityName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ])
  }
  create(value?: PartialMessage<Speciality>): Speciality {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.specialityId = 0
    message.specialityName = ''
    if (value !== undefined) reflectionMergePartial<Speciality>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: Speciality,
  ): Speciality {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 specialityId */ 1:
          message.specialityId = reader.int32()
          break
        case /* string specialityName */ 2:
          message.specialityName = reader.string()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: Speciality, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 specialityId = 1; */
    if (message.specialityId !== 0) writer.tag(1, WireType.Varint).int32(message.specialityId)
    /* string specialityName = 2; */
    if (message.specialityName !== '') writer.tag(2, WireType.LengthDelimited).string(message.specialityName)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message organizations_service.Speciality
 */
export const Speciality = new Speciality$Type()
// @generated message type with reflection information, may provide speed optimized methods
class SpecialitiesResponse$Type extends MessageType<SpecialitiesResponse> {
  constructor() {
    super('organizations_service.SpecialitiesResponse', [
      { no: 1, name: 'specialities', kind: 'message', repeat: 1 /*RepeatType.PACKED*/, T: () => Speciality },
    ])
  }
  create(value?: PartialMessage<SpecialitiesResponse>): SpecialitiesResponse {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.specialities = []
    if (value !== undefined) reflectionMergePartial<SpecialitiesResponse>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: SpecialitiesResponse,
  ): SpecialitiesResponse {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated organizations_service.Speciality specialities */ 1:
          message.specialities.push(Speciality.internalBinaryRead(reader, reader.uint32(), options))
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: SpecialitiesResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated organizations_service.Speciality specialities = 1; */
    for (let i = 0; i < message.specialities.length; i++)
      Speciality.internalBinaryWrite(
        message.specialities[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join()
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message organizations_service.SpecialitiesResponse
 */
export const SpecialitiesResponse = new SpecialitiesResponse$Type()
// @generated message type with reflection information, may provide speed optimized methods
class AddSpecialityRequest$Type extends MessageType<AddSpecialityRequest> {
  constructor() {
    super('organizations_service.AddSpecialityRequest', [
      { no: 1, name: 'organizationId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ])
  }
  create(value?: PartialMessage<AddSpecialityRequest>): AddSpecialityRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.organizationId = 0
    message.name = ''
    if (value !== undefined) reflectionMergePartial<AddSpecialityRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: AddSpecialityRequest,
  ): AddSpecialityRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 organizationId */ 1:
          message.organizationId = reader.int32()
          break
        case /* string name */ 2:
          message.name = reader.string()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: AddSpecialityRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 organizationId = 1; */
    if (message.organizationId !== 0) writer.tag(1, WireType.Varint).int32(message.organizationId)
    /* string name = 2; */
    if (message.name !== '') writer.tag(2, WireType.LengthDelimited).string(message.name)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message organizations_service.AddSpecialityRequest
 */
export const AddSpecialityRequest = new AddSpecialityRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class RemoveSpecialityRequest$Type extends MessageType<RemoveSpecialityRequest> {
  constructor() {
    super('organizations_service.RemoveSpecialityRequest', [
      { no: 1, name: 'organizationId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'specialityId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<RemoveSpecialityRequest>): RemoveSpecialityRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.organizationId = 0
    message.specialityId = 0
    if (value !== undefined) reflectionMergePartial<RemoveSpecialityRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: RemoveSpecialityRequest,
  ): RemoveSpecialityRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 organizationId */ 1:
          message.organizationId = reader.int32()
          break
        case /* int32 specialityId */ 2:
          message.specialityId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: RemoveSpecialityRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 organizationId = 1; */
    if (message.organizationId !== 0) writer.tag(1, WireType.Varint).int32(message.organizationId)
    /* int32 specialityId = 2; */
    if (message.specialityId !== 0) writer.tag(2, WireType.Varint).int32(message.specialityId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message organizations_service.RemoveSpecialityRequest
 */
export const RemoveSpecialityRequest = new RemoveSpecialityRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSpecialityRequest$Type extends MessageType<UpdateSpecialityRequest> {
  constructor() {
    super('organizations_service.UpdateSpecialityRequest', [
      { no: 1, name: 'organizationId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'specialityId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ])
  }
  create(value?: PartialMessage<UpdateSpecialityRequest>): UpdateSpecialityRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.organizationId = 0
    message.specialityId = 0
    message.name = ''
    if (value !== undefined) reflectionMergePartial<UpdateSpecialityRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateSpecialityRequest,
  ): UpdateSpecialityRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 organizationId */ 1:
          message.organizationId = reader.int32()
          break
        case /* int32 specialityId */ 2:
          message.specialityId = reader.int32()
          break
        case /* string name */ 3:
          message.name = reader.string()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: UpdateSpecialityRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 organizationId = 1; */
    if (message.organizationId !== 0) writer.tag(1, WireType.Varint).int32(message.organizationId)
    /* int32 specialityId = 2; */
    if (message.specialityId !== 0) writer.tag(2, WireType.Varint).int32(message.specialityId)
    /* string name = 3; */
    if (message.name !== '') writer.tag(3, WireType.LengthDelimited).string(message.name)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message organizations_service.UpdateSpecialityRequest
 */
export const UpdateSpecialityRequest = new UpdateSpecialityRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class SpecialityId$Type extends MessageType<SpecialityId> {
  constructor() {
    super('organizations_service.SpecialityId', [
      { no: 1, name: 'specialityId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<SpecialityId>): SpecialityId {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.specialityId = 0
    if (value !== undefined) reflectionMergePartial<SpecialityId>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: SpecialityId,
  ): SpecialityId {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 specialityId */ 1:
          message.specialityId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: SpecialityId, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 specialityId = 1; */
    if (message.specialityId !== 0) writer.tag(1, WireType.Varint).int32(message.specialityId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message organizations_service.SpecialityId
 */
export const SpecialityId = new SpecialityId$Type()
/**
 * @generated ServiceType for protobuf service organizations_service.Organizations
 */
export const Organizations = new ServiceType('organizations_service.Organizations', [
  { name: 'CreateOrganization', options: {}, I: OrganizationInfo, O: OrganizationId },
  { name: 'UpdateOrganizationInfo', options: {}, I: UpdateOrganizationInfoRequest, O: Empty },
  { name: 'GetOrganizationInfo', options: {}, I: OrganizationId, O: GetOrganizationInfoResponse },
  { name: 'GetAllOrganizationsInfo', options: {}, I: Empty, O: GetAllOrganizationsInfoResponse },
  { name: 'GetDeletedOrganizationsInfo', options: {}, I: Empty, O: GetAllOrganizationsInfoResponse },
  { name: 'DeleteOrganization', options: {}, I: OrganizationId, O: Empty },
  { name: 'RestoreOrganization', options: {}, I: OrganizationId, O: Empty },
  { name: 'IsValidOrganization', options: {}, I: OrganizationId, O: IsValidResponse },
  { name: 'GetOrganizationCode', options: {}, I: OrganizationId, O: OrganizationCode },
  { name: 'ValidateOrganizationCode', options: {}, I: OrganizationCode, O: OrganizationId },
  { name: 'GetOrganizationPermissions', options: {}, I: OrganizationId, O: Permissions },
  { name: 'GetAllOrganizationsPermissions', options: {}, I: Empty, O: GetAllOrganizationsPermissionsResponse },
  { name: 'AddAdmin', options: {}, I: UpdateAdminRequest, O: Empty },
  { name: 'RemoveAdmin', options: {}, I: UpdateAdminRequest, O: Empty },
  { name: 'GetAdmins', options: {}, I: OrganizationId, O: UsersResponse },
  { name: 'GetOrganizationSpecialities', options: {}, I: OrganizationId, O: SpecialitiesResponse },
  { name: 'AddSpeciality', options: {}, I: AddSpecialityRequest, O: SpecialityId },
  { name: 'RemoveSpeciality', options: {}, I: RemoveSpecialityRequest, O: Empty },
  { name: 'UpdateSpeciality', options: {}, I: UpdateSpecialityRequest, O: Empty },
])
