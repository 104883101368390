// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "organizations_service.proto" (package "organizations_service", syntax proto3)
// tslint:disable
//
// Protobuf definition file
//
import type { RpcTransport } from '@protobuf-ts/runtime-rpc'
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc'
import { Organizations } from './organizations_service'
import type { UpdateSpecialityRequest } from './organizations_service'
import type { RemoveSpecialityRequest } from './organizations_service'
import type { SpecialityId } from './organizations_service'
import type { AddSpecialityRequest } from './organizations_service'
import type { SpecialitiesResponse } from './organizations_service'
import type { UsersResponse } from './organizations_service'
import type { UpdateAdminRequest } from './organizations_service'
import type { GetAllOrganizationsPermissionsResponse } from './organizations_service'
import type { Permissions } from './organizations_service'
import type { OrganizationCode } from './organizations_service'
import type { IsValidResponse } from './organizations_service'
import type { GetAllOrganizationsInfoResponse } from './organizations_service'
import type { GetOrganizationInfoResponse } from './organizations_service'
import type { Empty } from './organizations_service'
import type { UpdateOrganizationInfoRequest } from './organizations_service'
import { stackIntercept } from '@protobuf-ts/runtime-rpc'
import type { OrganizationId } from './organizations_service'
import type { OrganizationInfo } from './organizations_service'
import type { UnaryCall } from '@protobuf-ts/runtime-rpc'
import type { RpcOptions } from '@protobuf-ts/runtime-rpc'
/**
 * The service definition.
 *
 * @generated from protobuf service organizations_service.Organizations
 */
export interface IOrganizationsClient {
  /**
   * @generated from protobuf rpc: CreateOrganization(organizations_service.OrganizationInfo) returns (organizations_service.OrganizationId);
   */
  createOrganization(input: OrganizationInfo, options?: RpcOptions): UnaryCall<OrganizationInfo, OrganizationId>
  /**
   * @generated from protobuf rpc: UpdateOrganizationInfo(organizations_service.UpdateOrganizationInfoRequest) returns (organizations_service.Empty);
   */
  updateOrganizationInfo(
    input: UpdateOrganizationInfoRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateOrganizationInfoRequest, Empty>
  /**
   * @generated from protobuf rpc: GetOrganizationInfo(organizations_service.OrganizationId) returns (organizations_service.GetOrganizationInfoResponse);
   */
  getOrganizationInfo(
    input: OrganizationId,
    options?: RpcOptions,
  ): UnaryCall<OrganizationId, GetOrganizationInfoResponse>
  /**
   * @generated from protobuf rpc: GetAllOrganizationsInfo(organizations_service.Empty) returns (organizations_service.GetAllOrganizationsInfoResponse);
   */
  getAllOrganizationsInfo(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetAllOrganizationsInfoResponse>
  /**
   * @generated from protobuf rpc: GetDeletedOrganizationsInfo(organizations_service.Empty) returns (organizations_service.GetAllOrganizationsInfoResponse);
   */
  getDeletedOrganizationsInfo(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetAllOrganizationsInfoResponse>
  /**
   * @generated from protobuf rpc: DeleteOrganization(organizations_service.OrganizationId) returns (organizations_service.Empty);
   */
  deleteOrganization(input: OrganizationId, options?: RpcOptions): UnaryCall<OrganizationId, Empty>
  /**
   * @generated from protobuf rpc: RestoreOrganization(organizations_service.OrganizationId) returns (organizations_service.Empty);
   */
  restoreOrganization(input: OrganizationId, options?: RpcOptions): UnaryCall<OrganizationId, Empty>
  /**
   * @generated from protobuf rpc: IsValidOrganization(organizations_service.OrganizationId) returns (organizations_service.IsValidResponse);
   */
  isValidOrganization(input: OrganizationId, options?: RpcOptions): UnaryCall<OrganizationId, IsValidResponse>
  /**
   * @generated from protobuf rpc: GetOrganizationCode(organizations_service.OrganizationId) returns (organizations_service.OrganizationCode);
   */
  getOrganizationCode(input: OrganizationId, options?: RpcOptions): UnaryCall<OrganizationId, OrganizationCode>
  /**
   * @generated from protobuf rpc: ValidateOrganizationCode(organizations_service.OrganizationCode) returns (organizations_service.OrganizationId);
   */
  validateOrganizationCode(input: OrganizationCode, options?: RpcOptions): UnaryCall<OrganizationCode, OrganizationId>
  /**
   * @generated from protobuf rpc: GetOrganizationPermissions(organizations_service.OrganizationId) returns (organizations_service.Permissions);
   */
  getOrganizationPermissions(input: OrganizationId, options?: RpcOptions): UnaryCall<OrganizationId, Permissions>
  /**
   * @generated from protobuf rpc: GetAllOrganizationsPermissions(organizations_service.Empty) returns (organizations_service.GetAllOrganizationsPermissionsResponse);
   */
  getAllOrganizationsPermissions(
    input: Empty,
    options?: RpcOptions,
  ): UnaryCall<Empty, GetAllOrganizationsPermissionsResponse>
  /**
   * @generated from protobuf rpc: AddAdmin(organizations_service.UpdateAdminRequest) returns (organizations_service.Empty);
   */
  addAdmin(input: UpdateAdminRequest, options?: RpcOptions): UnaryCall<UpdateAdminRequest, Empty>
  /**
   * @generated from protobuf rpc: RemoveAdmin(organizations_service.UpdateAdminRequest) returns (organizations_service.Empty);
   */
  removeAdmin(input: UpdateAdminRequest, options?: RpcOptions): UnaryCall<UpdateAdminRequest, Empty>
  /**
   * @generated from protobuf rpc: GetAdmins(organizations_service.OrganizationId) returns (organizations_service.UsersResponse);
   */
  getAdmins(input: OrganizationId, options?: RpcOptions): UnaryCall<OrganizationId, UsersResponse>
  /**
   * @generated from protobuf rpc: GetOrganizationSpecialities(organizations_service.OrganizationId) returns (organizations_service.SpecialitiesResponse);
   */
  getOrganizationSpecialities(
    input: OrganizationId,
    options?: RpcOptions,
  ): UnaryCall<OrganizationId, SpecialitiesResponse>
  /**
   * @generated from protobuf rpc: AddSpeciality(organizations_service.AddSpecialityRequest) returns (organizations_service.SpecialityId);
   */
  addSpeciality(input: AddSpecialityRequest, options?: RpcOptions): UnaryCall<AddSpecialityRequest, SpecialityId>
  /**
   * @generated from protobuf rpc: RemoveSpeciality(organizations_service.RemoveSpecialityRequest) returns (organizations_service.Empty);
   */
  removeSpeciality(input: RemoveSpecialityRequest, options?: RpcOptions): UnaryCall<RemoveSpecialityRequest, Empty>
  /**
   * @generated from protobuf rpc: UpdateSpeciality(organizations_service.UpdateSpecialityRequest) returns (organizations_service.Empty);
   */
  updateSpeciality(input: UpdateSpecialityRequest, options?: RpcOptions): UnaryCall<UpdateSpecialityRequest, Empty>
}
/**
 * The service definition.
 *
 * @generated from protobuf service organizations_service.Organizations
 */
export class OrganizationsClient implements IOrganizationsClient, ServiceInfo {
  typeName = Organizations.typeName
  methods = Organizations.methods
  options = Organizations.options
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: CreateOrganization(organizations_service.OrganizationInfo) returns (organizations_service.OrganizationId);
   */
  createOrganization(input: OrganizationInfo, options?: RpcOptions): UnaryCall<OrganizationInfo, OrganizationId> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<OrganizationInfo, OrganizationId>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: UpdateOrganizationInfo(organizations_service.UpdateOrganizationInfoRequest) returns (organizations_service.Empty);
   */
  updateOrganizationInfo(
    input: UpdateOrganizationInfoRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateOrganizationInfoRequest, Empty> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<UpdateOrganizationInfoRequest, Empty>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: GetOrganizationInfo(organizations_service.OrganizationId) returns (organizations_service.GetOrganizationInfoResponse);
   */
  getOrganizationInfo(
    input: OrganizationId,
    options?: RpcOptions,
  ): UnaryCall<OrganizationId, GetOrganizationInfoResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<OrganizationId, GetOrganizationInfoResponse>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: GetAllOrganizationsInfo(organizations_service.Empty) returns (organizations_service.GetAllOrganizationsInfoResponse);
   */
  getAllOrganizationsInfo(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetAllOrganizationsInfoResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<Empty, GetAllOrganizationsInfoResponse>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: GetDeletedOrganizationsInfo(organizations_service.Empty) returns (organizations_service.GetAllOrganizationsInfoResponse);
   */
  getDeletedOrganizationsInfo(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetAllOrganizationsInfoResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<Empty, GetAllOrganizationsInfoResponse>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: DeleteOrganization(organizations_service.OrganizationId) returns (organizations_service.Empty);
   */
  deleteOrganization(input: OrganizationId, options?: RpcOptions): UnaryCall<OrganizationId, Empty> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<OrganizationId, Empty>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: RestoreOrganization(organizations_service.OrganizationId) returns (organizations_service.Empty);
   */
  restoreOrganization(input: OrganizationId, options?: RpcOptions): UnaryCall<OrganizationId, Empty> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<OrganizationId, Empty>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: IsValidOrganization(organizations_service.OrganizationId) returns (organizations_service.IsValidResponse);
   */
  isValidOrganization(input: OrganizationId, options?: RpcOptions): UnaryCall<OrganizationId, IsValidResponse> {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<OrganizationId, IsValidResponse>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: GetOrganizationCode(organizations_service.OrganizationId) returns (organizations_service.OrganizationCode);
   */
  getOrganizationCode(input: OrganizationId, options?: RpcOptions): UnaryCall<OrganizationId, OrganizationCode> {
    const method = this.methods[8],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<OrganizationId, OrganizationCode>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: ValidateOrganizationCode(organizations_service.OrganizationCode) returns (organizations_service.OrganizationId);
   */
  validateOrganizationCode(input: OrganizationCode, options?: RpcOptions): UnaryCall<OrganizationCode, OrganizationId> {
    const method = this.methods[9],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<OrganizationCode, OrganizationId>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: GetOrganizationPermissions(organizations_service.OrganizationId) returns (organizations_service.Permissions);
   */
  getOrganizationPermissions(input: OrganizationId, options?: RpcOptions): UnaryCall<OrganizationId, Permissions> {
    const method = this.methods[10],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<OrganizationId, Permissions>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: GetAllOrganizationsPermissions(organizations_service.Empty) returns (organizations_service.GetAllOrganizationsPermissionsResponse);
   */
  getAllOrganizationsPermissions(
    input: Empty,
    options?: RpcOptions,
  ): UnaryCall<Empty, GetAllOrganizationsPermissionsResponse> {
    const method = this.methods[11],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<Empty, GetAllOrganizationsPermissionsResponse>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: AddAdmin(organizations_service.UpdateAdminRequest) returns (organizations_service.Empty);
   */
  addAdmin(input: UpdateAdminRequest, options?: RpcOptions): UnaryCall<UpdateAdminRequest, Empty> {
    const method = this.methods[12],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<UpdateAdminRequest, Empty>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: RemoveAdmin(organizations_service.UpdateAdminRequest) returns (organizations_service.Empty);
   */
  removeAdmin(input: UpdateAdminRequest, options?: RpcOptions): UnaryCall<UpdateAdminRequest, Empty> {
    const method = this.methods[13],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<UpdateAdminRequest, Empty>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: GetAdmins(organizations_service.OrganizationId) returns (organizations_service.UsersResponse);
   */
  getAdmins(input: OrganizationId, options?: RpcOptions): UnaryCall<OrganizationId, UsersResponse> {
    const method = this.methods[14],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<OrganizationId, UsersResponse>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: GetOrganizationSpecialities(organizations_service.OrganizationId) returns (organizations_service.SpecialitiesResponse);
   */
  getOrganizationSpecialities(
    input: OrganizationId,
    options?: RpcOptions,
  ): UnaryCall<OrganizationId, SpecialitiesResponse> {
    const method = this.methods[15],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<OrganizationId, SpecialitiesResponse>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: AddSpeciality(organizations_service.AddSpecialityRequest) returns (organizations_service.SpecialityId);
   */
  addSpeciality(input: AddSpecialityRequest, options?: RpcOptions): UnaryCall<AddSpecialityRequest, SpecialityId> {
    const method = this.methods[16],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<AddSpecialityRequest, SpecialityId>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: RemoveSpeciality(organizations_service.RemoveSpecialityRequest) returns (organizations_service.Empty);
   */
  removeSpeciality(input: RemoveSpecialityRequest, options?: RpcOptions): UnaryCall<RemoveSpecialityRequest, Empty> {
    const method = this.methods[17],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<RemoveSpecialityRequest, Empty>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: UpdateSpeciality(organizations_service.UpdateSpecialityRequest) returns (organizations_service.Empty);
   */
  updateSpeciality(input: UpdateSpecialityRequest, options?: RpcOptions): UnaryCall<UpdateSpecialityRequest, Empty> {
    const method = this.methods[18],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<UpdateSpecialityRequest, Empty>('unary', this._transport, method, opt, input)
  }
}
