import { defineStore } from 'pinia'
import api from '@/api/userInfo'
import apiFileManager from '@/api/fileManager'
import { User } from '@/protogen/userinfo_service/userinfo_service.ts'
import { toHex } from '@/helpers/toHex'

import { useOrganizationStore } from './organization'
import { UserOrganizationSpeciality } from '@/types/organization/organization'

interface State {
  user: User | null
  avatarUrl: string
  userSpecialities: UserOrganizationSpeciality[]
  availableOrganizations: number[]
  loading: boolean
}

export const useUserStore = defineStore('user', {
  state: (): State => {
    return {
      user: null,
      avatarUrl: '',
      userSpecialities: [],
      availableOrganizations: [],
      loading: false,
    }
  },
  actions: {
    async fetchUser() {
      try {
        this.loading = true
        this.user = await api().getUser(0, 0)

        if (this.user.pictureFileId.length) {
          const avatarResponse = await apiFileManager().getFile(toHex(this.user.pictureFileId))
          if (avatarResponse) {
            this.avatarUrl = avatarResponse.url
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async getSpecialities() {
      const organizationStore = useOrganizationStore()

      const organizations = await api().getCurrentUserMemberships()

      const specialities = await api().getOrganizationSpecialities([
        ...organizationStore.organizationsList.map((org) => org.organizationId),
      ])

      this.availableOrganizations = organizations.ids.sort((firstId, secondId) => firstId - secondId)

      this.userSpecialities = specialities.organizationIds
        .map((orgId, index) => {
          return { organizationId: orgId, specialityId: specialities.specialityIds[index] }
        })
        .sort((a, b) => a.organizationId - b.organizationId)
    },
    clearStore(): void {
      this.user = null
    },
  },
})
