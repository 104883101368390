import api from '@/api/organizations'

import { ErrorCodeEnum } from '@/interfaces/error'
import {
  GetAllOrganizationsInfoResponse,
  OrganizationInfo,
  Permissions,
} from '@/protogen/organizations_service/organizations_service'
import { OrganizationSpeciality } from '@/types/organization/organization'
import { defineStore } from 'pinia'
import { useUserStore } from './userStore'

export interface OrganizationStated {
  organizationId: number
  organizationInfo: OrganizationInfo
  specialities: OrganizationSpeciality[] | null
  permissions: Permissions
}

export interface State {
  organizationsList: OrganizationStated[]
  deletedOrganizationsList: OrganizationStated[]
  activeOrganizationId: number
  loading: boolean
  logoUrl: string
}

export const useOrganizationStore = defineStore('organization', {
  state: (): State => ({
    organizationsList: [],
    deletedOrganizationsList: [],
    activeOrganizationId: 0,
    loading: true,
    logoUrl: '',
  }),
  actions: {
    async fetchOrganizations() {
      try {
        const userStore = useUserStore()

        this.loading = true
        const orgsRsp = await api().getAllOrganizationsInfo()
        let orgsDeletedRsp = GetAllOrganizationsInfoResponse.create()
        try {
          orgsDeletedRsp = await api().getDeletedOrganizationsInfo()
        } catch (error: any) {
          if (error.code != ErrorCodeEnum.PERMISSION_DENIED) {
            throw error
          }
        }
        const orgPermissionsRsp = await api().getAllOrganizationsPermissions()

        const permissionsMap = new Map<number, Permissions>()
        orgPermissionsRsp.organizationIds.forEach((id, index) => {
          permissionsMap.set(id, orgPermissionsRsp.permissions[index])
        })

        const specialitiesMap = new Map<number, OrganizationSpeciality[] | null>()

        await Promise.all(
          orgsRsp.organizationIds.map(async (id) => {
            const list = await this.getSpecialities(id)
            specialitiesMap.set(id, list)
          }),
        )

        this.organizationsList = orgsRsp.organizationIds.map((id, index) => {
          return {
            organizationId: id,
            organizationInfo: orgsRsp.organizationInfos[index],
            permissions: permissionsMap.get(id) || ({} as Permissions),
            specialities: specialitiesMap.get(id) || null,
          }
        })

        this.deletedOrganizationsList = orgsDeletedRsp.organizationIds.map((id, index) => {
          return {
            organizationId: id,
            organizationInfo: orgsDeletedRsp.organizationInfos[index],
            permissions: permissionsMap.get(id) || ({} as Permissions),
            specialities: specialitiesMap.get(id) || null,
          }
        })

        await userStore.getSpecialities()
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async ensureOrganizationsLoaded() {
      if (!this.organizationsList.length) {
        await this.fetchOrganizations()
      }

      this.organizationsList = this.organizationsList
        .filter((org) => org.organizationId !== 1)
        .sort((a, b) => a.organizationId - b.organizationId)

      if (this.organizationsList.length) {
        this.activeOrganizationId = this.organizationsList[0].organizationId
      }
    },
    async getSpecialities(orgId: number): Promise<OrganizationSpeciality[] | null> {
      const response = await api().getOrganizationSpecialities(orgId)

      if (!response.specialities) return null

      return response.specialities.map((speciality) => {
        return { ...speciality, isEditing: false } as OrganizationSpeciality
      })
    },
    async fetchSpecialities(orgId: number): Promise<void> {
      const specialitiesList = await this.getSpecialities(orgId)

      if (!specialitiesList) return

      const organizationIndex = this.organizationsList.findIndex((org) => org.organizationId === orgId)

      this.organizationsList[organizationIndex].specialities = specialitiesList
    },
    getActiveOrganization(): OrganizationStated | null {
      return (
        this.organizationsList.find((org: OrganizationStated) => org.organizationId === this.activeOrganizationId) ||
        null
      )
    },
  },
})
