// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "userinfo_service.proto" (package "userinfo_service", syntax proto3)
// tslint:disable
import { ServiceType } from '@protobuf-ts/runtime-rpc'
import type { BinaryWriteOptions } from '@protobuf-ts/runtime'
import type { IBinaryWriter } from '@protobuf-ts/runtime'
import { WireType } from '@protobuf-ts/runtime'
import type { BinaryReadOptions } from '@protobuf-ts/runtime'
import type { IBinaryReader } from '@protobuf-ts/runtime'
import { UnknownFieldHandler } from '@protobuf-ts/runtime'
import type { PartialMessage } from '@protobuf-ts/runtime'
import { reflectionMergePartial } from '@protobuf-ts/runtime'
import { MessageType } from '@protobuf-ts/runtime'
/**
 * @generated from protobuf message userinfo_service.Emails
 */
export interface Emails {
  /**
   * @generated from protobuf field: repeated string emails = 1;
   */
  emails: string[]
}
/**
 * @generated from protobuf message userinfo_service.Ids
 */
export interface Ids {
  /**
   * @generated from protobuf field: repeated int32 ids = 1;
   */
  ids: number[]
}
/**
 * @generated from protobuf message userinfo_service.Empty
 */
export interface Empty {}
/**
 * @generated from protobuf message userinfo_service.Email
 */
export interface Email {
  /**
   * @generated from protobuf field: string email = 1;
   */
  email: string
}
/**
 * @generated from protobuf message userinfo_service.Id
 */
export interface Id {
  /**
   * @generated from protobuf field: int32 id = 1;
   */
  id: number
}
/**
 * @generated from protobuf message userinfo_service.UpdateSpecialityRequest
 */
export interface UpdateSpecialityRequest {
  /**
   * @generated from protobuf field: int32 organizationId = 1;
   */
  organizationId: number
  /**
   * @generated from protobuf field: int32 specialityId = 2;
   */
  specialityId: number
}
/**
 * @generated from protobuf message userinfo_service.UsersSpecialityRequest
 */
export interface UsersSpecialityRequest {
  /**
   * @generated from protobuf field: repeated int32 userIds = 1;
   */
  userIds: number[]
  /**
   * @generated from protobuf field: int32 organizationId = 2;
   */
  organizationId: number
}
/**
 * @generated from protobuf message userinfo_service.UserOrganizationSpecialities
 */
export interface UserOrganizationSpecialities {
  /**
   * @generated from protobuf field: repeated int32 organizationIds = 1;
   */
  organizationIds: number[]
  /**
   * @generated from protobuf field: repeated int32 specialityIds = 2;
   */
  specialityIds: number[]
}
/**
 * @generated from protobuf message userinfo_service.OrganizationSpecialities
 */
export interface OrganizationSpecialities {
  /**
   * @generated from protobuf field: repeated int32 userIds = 1;
   */
  userIds: number[]
  /**
   * @generated from protobuf field: repeated int32 specialityIds = 2;
   */
  specialityIds: number[]
}
/**
 * @generated from protobuf message userinfo_service.MemberRequest
 */
export interface MemberRequest {
  /**
   * @generated from protobuf field: int32 user_id = 1;
   */
  userId: number
  /**
   * @generated from protobuf field: int32 organization_id = 2;
   */
  organizationId: number
}
/**
 * @generated from protobuf message userinfo_service.InviteCode
 */
export interface InviteCode {
  /**
   * @generated from protobuf field: string code = 1;
   */
  code: string
}
/**
 * @generated from protobuf message userinfo_service.Statuses
 */
export interface Statuses {
  /**
   * @generated from protobuf field: repeated int32 statuses = 1;
   */
  statuses: number[]
}
/**
 * @generated from protobuf message userinfo_service.Universities
 */
export interface Universities {
  /**
   * @generated from protobuf field: repeated int32 ids = 1;
   */
  ids: number[]
  /**
   * @generated from protobuf field: repeated userinfo_service.University universities = 2;
   */
  universities: University[]
}
/**
 * @generated from protobuf message userinfo_service.University
 */
export interface University {
  /**
   * @generated from protobuf field: string name = 1;
   */
  name: string
}
/**
 * @generated from protobuf message userinfo_service.UserRequest
 */
export interface UserRequest {
  /**
   * @generated from protobuf field: int32 user_id = 1;
   */
  userId: number
  /**
   * @generated from protobuf field: int32 course_id = 2;
   */
  courseId: number
}
/**
 * @generated from protobuf message userinfo_service.UsersRequest
 */
export interface UsersRequest {
  /**
   * @generated from protobuf field: repeated int32 user_ids = 1;
   */
  userIds: number[]
  /**
   * @generated from protobuf field: int32 course_id = 2;
   */
  courseId: number
}
/**
 * @generated from protobuf message userinfo_service.UserEmailRequest
 */
export interface UserEmailRequest {
  /**
   * @generated from protobuf field: int32 course_id = 1;
   */
  courseId: number
  /**
   * @generated from protobuf field: string email = 2;
   */
  email: string
}
/**
 * @generated from protobuf message userinfo_service.User
 */
export interface User {
  /**
   * @generated from protobuf field: string email = 1;
   */
  email: string
  /**
   * @generated from protobuf field: string first_name = 2;
   */
  firstName: string
  /**
   * @generated from protobuf field: string last_name = 3;
   */
  lastName: string
  /**
   * @generated from protobuf field: string phone_number = 4;
   */
  phoneNumber: string
  /**
   * @generated from protobuf field: int64 birth_date = 5;
   */
  birthDate: bigint
  /**
   * @generated from protobuf field: int32 university_id = 6;
   */
  universityId: number
  /**
   * @generated from protobuf field: int32 graduation_year = 7;
   */
  graduationYear: number
  /**
   * @generated from protobuf field: bytes picture_file_id = 8;
   */
  pictureFileId: Uint8Array
  /**
   * @generated from protobuf field: bool verified = 9;
   */
  verified: boolean
}
/**
 * @generated from protobuf message userinfo_service.Users
 */
export interface Users {
  /**
   * @generated from protobuf field: repeated int32 ids = 1;
   */
  ids: number[]
  /**
   * @generated from protobuf field: repeated userinfo_service.User users = 2;
   */
  users: User[]
}
/**
 * @generated from protobuf message userinfo_service.ApprovalRequestEntry
 */
export interface ApprovalRequestEntry {
  /**
   * @generated from protobuf field: string first_name = 1;
   */
  firstName: string
  /**
   * @generated from protobuf field: string last_name = 2;
   */
  lastName: string
  /**
   * @generated from protobuf field: string phone_number = 3;
   */
  phoneNumber: string
  /**
   * @generated from protobuf field: int64 birth_date = 4;
   */
  birthDate: bigint
  /**
   * @generated from protobuf field: int32 university_id = 5;
   */
  universityId: number
  /**
   * @generated from protobuf field: int32 graduation_year = 6;
   */
  graduationYear: number
  /**
   * @generated from protobuf field: bytes picture_file_id = 7;
   */
  pictureFileId: Uint8Array
  /**
   * @generated from protobuf field: string organization_code = 8;
   */
  organizationCode: string
}
/**
 * @generated from protobuf message userinfo_service.InformationChangeEntry
 */
export interface InformationChangeEntry {
  /**
   * @generated from protobuf field: string first_name = 1;
   */
  firstName: string
  /**
   * @generated from protobuf field: string last_name = 2;
   */
  lastName: string
  /**
   * @generated from protobuf field: string phone_number = 3;
   */
  phoneNumber: string
  /**
   * @generated from protobuf field: int64 birth_date = 4;
   */
  birthDate: bigint
  /**
   * @generated from protobuf field: int32 university_id = 5;
   */
  universityId: number
  /**
   * @generated from protobuf field: int32 graduation_year = 6;
   */
  graduationYear: number
  /**
   * @generated from protobuf field: bytes picture_file_id = 7;
   */
  pictureFileId: Uint8Array
}
/**
 * @generated from protobuf message userinfo_service.RequestInfo
 */
export interface RequestInfo {
  /**
   * @generated from protobuf field: string email = 1;
   */
  email: string
  /**
   * @generated from protobuf field: string first_name = 2;
   */
  firstName: string
  /**
   * @generated from protobuf field: string last_name = 3;
   */
  lastName: string
  /**
   * @generated from protobuf field: string phone_number = 4;
   */
  phoneNumber: string
  /**
   * @generated from protobuf field: int64 birth_date = 5;
   */
  birthDate: bigint
  /**
   * @generated from protobuf field: int32 university_id = 6;
   */
  universityId: number
  /**
   * @generated from protobuf field: int32 graduation_year = 7;
   */
  graduationYear: number
  /**
   * @generated from protobuf field: bytes picture_file_id = 8;
   */
  pictureFileId: Uint8Array
  /**
   * @generated from protobuf field: int32 status = 9;
   */
  status: number
  /**
   * @generated from protobuf field: bool user_already_verified = 10;
   */
  userAlreadyVerified: boolean
  /**
   * @generated from protobuf field: int32 organization_id = 11;
   */
  organizationId: number
}
/**
 * @generated from protobuf message userinfo_service.PendingRequests
 */
export interface PendingRequests {
  /**
   * @generated from protobuf field: repeated int32 ids = 1;
   */
  ids: number[]
  /**
   * @generated from protobuf field: repeated userinfo_service.Request requests = 2;
   */
  requests: Request[]
}
/**
 * @generated from protobuf message userinfo_service.Request
 */
export interface Request {
  /**
   * @generated from protobuf field: int32 user_id = 1;
   */
  userId: number
  /**
   * @generated from protobuf field: string email = 2;
   */
  email: string
  /**
   * @generated from protobuf field: string first_name = 3;
   */
  firstName: string
  /**
   * @generated from protobuf field: string last_name = 4;
   */
  lastName: string
  /**
   * @generated from protobuf field: string phone_number = 5;
   */
  phoneNumber: string
  /**
   * @generated from protobuf field: int64 birth_date = 6;
   */
  birthDate: bigint
  /**
   * @generated from protobuf field: int32 university_id = 7;
   */
  universityId: number
  /**
   * @generated from protobuf field: int32 graduation_year = 8;
   */
  graduationYear: number
  /**
   * @generated from protobuf field: bytes picture_file_id = 9;
   */
  pictureFileId: Uint8Array
  /**
   * @generated from protobuf field: int32 status = 10;
   */
  status: number
  /**
   * @generated from protobuf field: bool user_already_verified = 11;
   */
  userAlreadyVerified: boolean
  /**
   * @generated from protobuf field: int32 organization_id = 12;
   */
  organizationId: number
}
/**
 * optional
 *
 * @generated from protobuf message userinfo_service.Filters
 */
export interface Filters {
  /**
   * @generated from protobuf field: int32 organization_id = 1;
   */
  organizationId: number
}
// @generated message type with reflection information, may provide speed optimized methods
class Emails$Type extends MessageType<Emails> {
  constructor() {
    super('userinfo_service.Emails', [
      { no: 1, name: 'emails', kind: 'scalar', repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
    ])
  }
  create(value?: PartialMessage<Emails>): Emails {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.emails = []
    if (value !== undefined) reflectionMergePartial<Emails>(this, message, value)
    return message
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Emails): Emails {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated string emails */ 1:
          message.emails.push(reader.string())
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: Emails, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* repeated string emails = 1; */
    for (let i = 0; i < message.emails.length; i++) writer.tag(1, WireType.LengthDelimited).string(message.emails[i])
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.Emails
 */
export const Emails = new Emails$Type()
// @generated message type with reflection information, may provide speed optimized methods
class Ids$Type extends MessageType<Ids> {
  constructor() {
    super('userinfo_service.Ids', [
      { no: 1, name: 'ids', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<Ids>): Ids {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.ids = []
    if (value !== undefined) reflectionMergePartial<Ids>(this, message, value)
    return message
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Ids): Ids {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated int32 ids */ 1:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.ids.push(reader.int32())
          else message.ids.push(reader.int32())
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: Ids, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* repeated int32 ids = 1; */
    if (message.ids.length) {
      writer.tag(1, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.ids.length; i++) writer.int32(message.ids[i])
      writer.join()
    }
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.Ids
 */
export const Ids = new Ids$Type()
// @generated message type with reflection information, may provide speed optimized methods
class Empty$Type extends MessageType<Empty> {
  constructor() {
    super('userinfo_service.Empty', [])
  }
  create(value?: PartialMessage<Empty>): Empty {
    const message = globalThis.Object.create(this.messagePrototype!)
    if (value !== undefined) reflectionMergePartial<Empty>(this, message, value)
    return message
  }
  internalBinaryRead(target?: Empty): Empty {
    return target ?? this.create()
  }
  internalBinaryWrite(message: Empty, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.Empty
 */
export const Empty = new Empty$Type()
// @generated message type with reflection information, may provide speed optimized methods
class Email$Type extends MessageType<Email> {
  constructor() {
    super('userinfo_service.Email', [{ no: 1, name: 'email', kind: 'scalar', T: 9 /*ScalarType.STRING*/ }])
  }
  create(value?: PartialMessage<Email>): Email {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.email = ''
    if (value !== undefined) reflectionMergePartial<Email>(this, message, value)
    return message
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Email): Email {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* string email */ 1:
          message.email = reader.string()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: Email, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* string email = 1; */
    if (message.email !== '') writer.tag(1, WireType.LengthDelimited).string(message.email)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.Email
 */
export const Email = new Email$Type()
// @generated message type with reflection information, may provide speed optimized methods
class Id$Type extends MessageType<Id> {
  constructor() {
    super('userinfo_service.Id', [{ no: 1, name: 'id', kind: 'scalar', T: 5 /*ScalarType.INT32*/ }])
  }
  create(value?: PartialMessage<Id>): Id {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.id = 0
    if (value !== undefined) reflectionMergePartial<Id>(this, message, value)
    return message
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Id): Id {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 id */ 1:
          message.id = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: Id, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int32(message.id)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.Id
 */
export const Id = new Id$Type()
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSpecialityRequest$Type extends MessageType<UpdateSpecialityRequest> {
  constructor() {
    super('userinfo_service.UpdateSpecialityRequest', [
      { no: 1, name: 'organizationId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'specialityId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<UpdateSpecialityRequest>): UpdateSpecialityRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.organizationId = 0
    message.specialityId = 0
    if (value !== undefined) reflectionMergePartial<UpdateSpecialityRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateSpecialityRequest,
  ): UpdateSpecialityRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 organizationId */ 1:
          message.organizationId = reader.int32()
          break
        case /* int32 specialityId */ 2:
          message.specialityId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: UpdateSpecialityRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 organizationId = 1; */
    if (message.organizationId !== 0) writer.tag(1, WireType.Varint).int32(message.organizationId)
    /* int32 specialityId = 2; */
    if (message.specialityId !== 0) writer.tag(2, WireType.Varint).int32(message.specialityId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.UpdateSpecialityRequest
 */
export const UpdateSpecialityRequest = new UpdateSpecialityRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class UsersSpecialityRequest$Type extends MessageType<UsersSpecialityRequest> {
  constructor() {
    super('userinfo_service.UsersSpecialityRequest', [
      { no: 1, name: 'userIds', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'organizationId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<UsersSpecialityRequest>): UsersSpecialityRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.userIds = []
    message.organizationId = 0
    if (value !== undefined) reflectionMergePartial<UsersSpecialityRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UsersSpecialityRequest,
  ): UsersSpecialityRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated int32 userIds */ 1:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.userIds.push(reader.int32())
          else message.userIds.push(reader.int32())
          break
        case /* int32 organizationId */ 2:
          message.organizationId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: UsersSpecialityRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated int32 userIds = 1; */
    if (message.userIds.length) {
      writer.tag(1, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.userIds.length; i++) writer.int32(message.userIds[i])
      writer.join()
    }
    /* int32 organizationId = 2; */
    if (message.organizationId !== 0) writer.tag(2, WireType.Varint).int32(message.organizationId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.UsersSpecialityRequest
 */
export const UsersSpecialityRequest = new UsersSpecialityRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class UserOrganizationSpecialities$Type extends MessageType<UserOrganizationSpecialities> {
  constructor() {
    super('userinfo_service.UserOrganizationSpecialities', [
      { no: 1, name: 'organizationIds', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'specialityIds', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<UserOrganizationSpecialities>): UserOrganizationSpecialities {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.organizationIds = []
    message.specialityIds = []
    if (value !== undefined) reflectionMergePartial<UserOrganizationSpecialities>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UserOrganizationSpecialities,
  ): UserOrganizationSpecialities {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated int32 organizationIds */ 1:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.organizationIds.push(reader.int32())
          else message.organizationIds.push(reader.int32())
          break
        case /* repeated int32 specialityIds */ 2:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.specialityIds.push(reader.int32())
          else message.specialityIds.push(reader.int32())
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: UserOrganizationSpecialities,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated int32 organizationIds = 1; */
    if (message.organizationIds.length) {
      writer.tag(1, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.organizationIds.length; i++) writer.int32(message.organizationIds[i])
      writer.join()
    }
    /* repeated int32 specialityIds = 2; */
    if (message.specialityIds.length) {
      writer.tag(2, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.specialityIds.length; i++) writer.int32(message.specialityIds[i])
      writer.join()
    }
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.UserOrganizationSpecialities
 */
export const UserOrganizationSpecialities = new UserOrganizationSpecialities$Type()
// @generated message type with reflection information, may provide speed optimized methods
class OrganizationSpecialities$Type extends MessageType<OrganizationSpecialities> {
  constructor() {
    super('userinfo_service.OrganizationSpecialities', [
      { no: 1, name: 'userIds', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'specialityIds', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<OrganizationSpecialities>): OrganizationSpecialities {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.userIds = []
    message.specialityIds = []
    if (value !== undefined) reflectionMergePartial<OrganizationSpecialities>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: OrganizationSpecialities,
  ): OrganizationSpecialities {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated int32 userIds */ 1:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.userIds.push(reader.int32())
          else message.userIds.push(reader.int32())
          break
        case /* repeated int32 specialityIds */ 2:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.specialityIds.push(reader.int32())
          else message.specialityIds.push(reader.int32())
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: OrganizationSpecialities,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated int32 userIds = 1; */
    if (message.userIds.length) {
      writer.tag(1, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.userIds.length; i++) writer.int32(message.userIds[i])
      writer.join()
    }
    /* repeated int32 specialityIds = 2; */
    if (message.specialityIds.length) {
      writer.tag(2, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.specialityIds.length; i++) writer.int32(message.specialityIds[i])
      writer.join()
    }
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.OrganizationSpecialities
 */
export const OrganizationSpecialities = new OrganizationSpecialities$Type()
// @generated message type with reflection information, may provide speed optimized methods
class MemberRequest$Type extends MessageType<MemberRequest> {
  constructor() {
    super('userinfo_service.MemberRequest', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'organization_id', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<MemberRequest>): MemberRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.userId = 0
    message.organizationId = 0
    if (value !== undefined) reflectionMergePartial<MemberRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: MemberRequest,
  ): MemberRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 user_id */ 1:
          message.userId = reader.int32()
          break
        case /* int32 organization_id */ 2:
          message.organizationId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: MemberRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 user_id = 1; */
    if (message.userId !== 0) writer.tag(1, WireType.Varint).int32(message.userId)
    /* int32 organization_id = 2; */
    if (message.organizationId !== 0) writer.tag(2, WireType.Varint).int32(message.organizationId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.MemberRequest
 */
export const MemberRequest = new MemberRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class InviteCode$Type extends MessageType<InviteCode> {
  constructor() {
    super('userinfo_service.InviteCode', [{ no: 1, name: 'code', kind: 'scalar', T: 9 /*ScalarType.STRING*/ }])
  }
  create(value?: PartialMessage<InviteCode>): InviteCode {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.code = ''
    if (value !== undefined) reflectionMergePartial<InviteCode>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: InviteCode,
  ): InviteCode {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* string code */ 1:
          message.code = reader.string()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: InviteCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* string code = 1; */
    if (message.code !== '') writer.tag(1, WireType.LengthDelimited).string(message.code)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.InviteCode
 */
export const InviteCode = new InviteCode$Type()
// @generated message type with reflection information, may provide speed optimized methods
class Statuses$Type extends MessageType<Statuses> {
  constructor() {
    super('userinfo_service.Statuses', [
      { no: 1, name: 'statuses', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<Statuses>): Statuses {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.statuses = []
    if (value !== undefined) reflectionMergePartial<Statuses>(this, message, value)
    return message
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Statuses): Statuses {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated int32 statuses */ 1:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.statuses.push(reader.int32())
          else message.statuses.push(reader.int32())
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: Statuses, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* repeated int32 statuses = 1; */
    if (message.statuses.length) {
      writer.tag(1, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.statuses.length; i++) writer.int32(message.statuses[i])
      writer.join()
    }
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.Statuses
 */
export const Statuses = new Statuses$Type()
// @generated message type with reflection information, may provide speed optimized methods
class Universities$Type extends MessageType<Universities> {
  constructor() {
    super('userinfo_service.Universities', [
      { no: 1, name: 'ids', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'universities', kind: 'message', repeat: 1 /*RepeatType.PACKED*/, T: () => University },
    ])
  }
  create(value?: PartialMessage<Universities>): Universities {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.ids = []
    message.universities = []
    if (value !== undefined) reflectionMergePartial<Universities>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: Universities,
  ): Universities {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated int32 ids */ 1:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.ids.push(reader.int32())
          else message.ids.push(reader.int32())
          break
        case /* repeated userinfo_service.University universities */ 2:
          message.universities.push(University.internalBinaryRead(reader, reader.uint32(), options))
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: Universities, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* repeated int32 ids = 1; */
    if (message.ids.length) {
      writer.tag(1, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.ids.length; i++) writer.int32(message.ids[i])
      writer.join()
    }
    /* repeated userinfo_service.University universities = 2; */
    for (let i = 0; i < message.universities.length; i++)
      University.internalBinaryWrite(
        message.universities[i],
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join()
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.Universities
 */
export const Universities = new Universities$Type()
// @generated message type with reflection information, may provide speed optimized methods
class University$Type extends MessageType<University> {
  constructor() {
    super('userinfo_service.University', [{ no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ }])
  }
  create(value?: PartialMessage<University>): University {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.name = ''
    if (value !== undefined) reflectionMergePartial<University>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: University,
  ): University {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* string name */ 1:
          message.name = reader.string()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: University, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* string name = 1; */
    if (message.name !== '') writer.tag(1, WireType.LengthDelimited).string(message.name)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.University
 */
export const University = new University$Type()
// @generated message type with reflection information, may provide speed optimized methods
class UserRequest$Type extends MessageType<UserRequest> {
  constructor() {
    super('userinfo_service.UserRequest', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'course_id', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<UserRequest>): UserRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.userId = 0
    message.courseId = 0
    if (value !== undefined) reflectionMergePartial<UserRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UserRequest,
  ): UserRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 user_id */ 1:
          message.userId = reader.int32()
          break
        case /* int32 course_id */ 2:
          message.courseId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: UserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 user_id = 1; */
    if (message.userId !== 0) writer.tag(1, WireType.Varint).int32(message.userId)
    /* int32 course_id = 2; */
    if (message.courseId !== 0) writer.tag(2, WireType.Varint).int32(message.courseId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.UserRequest
 */
export const UserRequest = new UserRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class UsersRequest$Type extends MessageType<UsersRequest> {
  constructor() {
    super('userinfo_service.UsersRequest', [
      { no: 1, name: 'user_ids', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'course_id', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<UsersRequest>): UsersRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.userIds = []
    message.courseId = 0
    if (value !== undefined) reflectionMergePartial<UsersRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UsersRequest,
  ): UsersRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated int32 user_ids */ 1:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.userIds.push(reader.int32())
          else message.userIds.push(reader.int32())
          break
        case /* int32 course_id */ 2:
          message.courseId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: UsersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* repeated int32 user_ids = 1; */
    if (message.userIds.length) {
      writer.tag(1, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.userIds.length; i++) writer.int32(message.userIds[i])
      writer.join()
    }
    /* int32 course_id = 2; */
    if (message.courseId !== 0) writer.tag(2, WireType.Varint).int32(message.courseId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.UsersRequest
 */
export const UsersRequest = new UsersRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class UserEmailRequest$Type extends MessageType<UserEmailRequest> {
  constructor() {
    super('userinfo_service.UserEmailRequest', [
      { no: 1, name: 'course_id', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'email', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ])
  }
  create(value?: PartialMessage<UserEmailRequest>): UserEmailRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.courseId = 0
    message.email = ''
    if (value !== undefined) reflectionMergePartial<UserEmailRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UserEmailRequest,
  ): UserEmailRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 course_id */ 1:
          message.courseId = reader.int32()
          break
        case /* string email */ 2:
          message.email = reader.string()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: UserEmailRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 course_id = 1; */
    if (message.courseId !== 0) writer.tag(1, WireType.Varint).int32(message.courseId)
    /* string email = 2; */
    if (message.email !== '') writer.tag(2, WireType.LengthDelimited).string(message.email)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.UserEmailRequest
 */
export const UserEmailRequest = new UserEmailRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class User$Type extends MessageType<User> {
  constructor() {
    super('userinfo_service.User', [
      { no: 1, name: 'email', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'first_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'last_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'phone_number', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'birth_date', kind: 'scalar', T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
      { no: 6, name: 'university_id', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 7, name: 'graduation_year', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 8, name: 'picture_file_id', kind: 'scalar', T: 12 /*ScalarType.BYTES*/ },
      { no: 9, name: 'verified', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ])
  }
  create(value?: PartialMessage<User>): User {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.email = ''
    message.firstName = ''
    message.lastName = ''
    message.phoneNumber = ''
    message.birthDate = 0n
    message.universityId = 0
    message.graduationYear = 0
    message.pictureFileId = new Uint8Array(0)
    message.verified = false
    if (value !== undefined) reflectionMergePartial<User>(this, message, value)
    return message
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: User): User {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* string email */ 1:
          message.email = reader.string()
          break
        case /* string first_name */ 2:
          message.firstName = reader.string()
          break
        case /* string last_name */ 3:
          message.lastName = reader.string()
          break
        case /* string phone_number */ 4:
          message.phoneNumber = reader.string()
          break
        case /* int64 birth_date */ 5:
          message.birthDate = reader.int64().toBigInt()
          break
        case /* int32 university_id */ 6:
          message.universityId = reader.int32()
          break
        case /* int32 graduation_year */ 7:
          message.graduationYear = reader.int32()
          break
        case /* bytes picture_file_id */ 8:
          message.pictureFileId = reader.bytes()
          break
        case /* bool verified */ 9:
          message.verified = reader.bool()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: User, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* string email = 1; */
    if (message.email !== '') writer.tag(1, WireType.LengthDelimited).string(message.email)
    /* string first_name = 2; */
    if (message.firstName !== '') writer.tag(2, WireType.LengthDelimited).string(message.firstName)
    /* string last_name = 3; */
    if (message.lastName !== '') writer.tag(3, WireType.LengthDelimited).string(message.lastName)
    /* string phone_number = 4; */
    if (message.phoneNumber !== '') writer.tag(4, WireType.LengthDelimited).string(message.phoneNumber)
    /* int64 birth_date = 5; */
    if (message.birthDate !== 0n) writer.tag(5, WireType.Varint).int64(message.birthDate)
    /* int32 university_id = 6; */
    if (message.universityId !== 0) writer.tag(6, WireType.Varint).int32(message.universityId)
    /* int32 graduation_year = 7; */
    if (message.graduationYear !== 0) writer.tag(7, WireType.Varint).int32(message.graduationYear)
    /* bytes picture_file_id = 8; */
    if (message.pictureFileId.length) writer.tag(8, WireType.LengthDelimited).bytes(message.pictureFileId)
    /* bool verified = 9; */
    if (message.verified !== false) writer.tag(9, WireType.Varint).bool(message.verified)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.User
 */
export const User = new User$Type()
// @generated message type with reflection information, may provide speed optimized methods
class Users$Type extends MessageType<Users> {
  constructor() {
    super('userinfo_service.Users', [
      { no: 1, name: 'ids', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'users', kind: 'message', repeat: 1 /*RepeatType.PACKED*/, T: () => User },
    ])
  }
  create(value?: PartialMessage<Users>): Users {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.ids = []
    message.users = []
    if (value !== undefined) reflectionMergePartial<Users>(this, message, value)
    return message
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Users): Users {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated int32 ids */ 1:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.ids.push(reader.int32())
          else message.ids.push(reader.int32())
          break
        case /* repeated userinfo_service.User users */ 2:
          message.users.push(User.internalBinaryRead(reader, reader.uint32(), options))
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: Users, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* repeated int32 ids = 1; */
    if (message.ids.length) {
      writer.tag(1, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.ids.length; i++) writer.int32(message.ids[i])
      writer.join()
    }
    /* repeated userinfo_service.User users = 2; */
    for (let i = 0; i < message.users.length; i++)
      User.internalBinaryWrite(message.users[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join()
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.Users
 */
export const Users = new Users$Type()
// @generated message type with reflection information, may provide speed optimized methods
class ApprovalRequestEntry$Type extends MessageType<ApprovalRequestEntry> {
  constructor() {
    super('userinfo_service.ApprovalRequestEntry', [
      { no: 1, name: 'first_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'last_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'phone_number', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'birth_date', kind: 'scalar', T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
      { no: 5, name: 'university_id', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 6, name: 'graduation_year', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 7, name: 'picture_file_id', kind: 'scalar', T: 12 /*ScalarType.BYTES*/ },
      { no: 8, name: 'organization_code', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ])
  }
  create(value?: PartialMessage<ApprovalRequestEntry>): ApprovalRequestEntry {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.firstName = ''
    message.lastName = ''
    message.phoneNumber = ''
    message.birthDate = 0n
    message.universityId = 0
    message.graduationYear = 0
    message.pictureFileId = new Uint8Array(0)
    message.organizationCode = ''
    if (value !== undefined) reflectionMergePartial<ApprovalRequestEntry>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ApprovalRequestEntry,
  ): ApprovalRequestEntry {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* string first_name */ 1:
          message.firstName = reader.string()
          break
        case /* string last_name */ 2:
          message.lastName = reader.string()
          break
        case /* string phone_number */ 3:
          message.phoneNumber = reader.string()
          break
        case /* int64 birth_date */ 4:
          message.birthDate = reader.int64().toBigInt()
          break
        case /* int32 university_id */ 5:
          message.universityId = reader.int32()
          break
        case /* int32 graduation_year */ 6:
          message.graduationYear = reader.int32()
          break
        case /* bytes picture_file_id */ 7:
          message.pictureFileId = reader.bytes()
          break
        case /* string organization_code */ 8:
          message.organizationCode = reader.string()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: ApprovalRequestEntry,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string first_name = 1; */
    if (message.firstName !== '') writer.tag(1, WireType.LengthDelimited).string(message.firstName)
    /* string last_name = 2; */
    if (message.lastName !== '') writer.tag(2, WireType.LengthDelimited).string(message.lastName)
    /* string phone_number = 3; */
    if (message.phoneNumber !== '') writer.tag(3, WireType.LengthDelimited).string(message.phoneNumber)
    /* int64 birth_date = 4; */
    if (message.birthDate !== 0n) writer.tag(4, WireType.Varint).int64(message.birthDate)
    /* int32 university_id = 5; */
    if (message.universityId !== 0) writer.tag(5, WireType.Varint).int32(message.universityId)
    /* int32 graduation_year = 6; */
    if (message.graduationYear !== 0) writer.tag(6, WireType.Varint).int32(message.graduationYear)
    /* bytes picture_file_id = 7; */
    if (message.pictureFileId.length) writer.tag(7, WireType.LengthDelimited).bytes(message.pictureFileId)
    /* string organization_code = 8; */
    if (message.organizationCode !== '') writer.tag(8, WireType.LengthDelimited).string(message.organizationCode)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.ApprovalRequestEntry
 */
export const ApprovalRequestEntry = new ApprovalRequestEntry$Type()
// @generated message type with reflection information, may provide speed optimized methods
class InformationChangeEntry$Type extends MessageType<InformationChangeEntry> {
  constructor() {
    super('userinfo_service.InformationChangeEntry', [
      { no: 1, name: 'first_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'last_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'phone_number', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'birth_date', kind: 'scalar', T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
      { no: 5, name: 'university_id', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 6, name: 'graduation_year', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 7, name: 'picture_file_id', kind: 'scalar', T: 12 /*ScalarType.BYTES*/ },
    ])
  }
  create(value?: PartialMessage<InformationChangeEntry>): InformationChangeEntry {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.firstName = ''
    message.lastName = ''
    message.phoneNumber = ''
    message.birthDate = 0n
    message.universityId = 0
    message.graduationYear = 0
    message.pictureFileId = new Uint8Array(0)
    if (value !== undefined) reflectionMergePartial<InformationChangeEntry>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: InformationChangeEntry,
  ): InformationChangeEntry {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* string first_name */ 1:
          message.firstName = reader.string()
          break
        case /* string last_name */ 2:
          message.lastName = reader.string()
          break
        case /* string phone_number */ 3:
          message.phoneNumber = reader.string()
          break
        case /* int64 birth_date */ 4:
          message.birthDate = reader.int64().toBigInt()
          break
        case /* int32 university_id */ 5:
          message.universityId = reader.int32()
          break
        case /* int32 graduation_year */ 6:
          message.graduationYear = reader.int32()
          break
        case /* bytes picture_file_id */ 7:
          message.pictureFileId = reader.bytes()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: InformationChangeEntry,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string first_name = 1; */
    if (message.firstName !== '') writer.tag(1, WireType.LengthDelimited).string(message.firstName)
    /* string last_name = 2; */
    if (message.lastName !== '') writer.tag(2, WireType.LengthDelimited).string(message.lastName)
    /* string phone_number = 3; */
    if (message.phoneNumber !== '') writer.tag(3, WireType.LengthDelimited).string(message.phoneNumber)
    /* int64 birth_date = 4; */
    if (message.birthDate !== 0n) writer.tag(4, WireType.Varint).int64(message.birthDate)
    /* int32 university_id = 5; */
    if (message.universityId !== 0) writer.tag(5, WireType.Varint).int32(message.universityId)
    /* int32 graduation_year = 6; */
    if (message.graduationYear !== 0) writer.tag(6, WireType.Varint).int32(message.graduationYear)
    /* bytes picture_file_id = 7; */
    if (message.pictureFileId.length) writer.tag(7, WireType.LengthDelimited).bytes(message.pictureFileId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.InformationChangeEntry
 */
export const InformationChangeEntry = new InformationChangeEntry$Type()
// @generated message type with reflection information, may provide speed optimized methods
class RequestInfo$Type extends MessageType<RequestInfo> {
  constructor() {
    super('userinfo_service.RequestInfo', [
      { no: 1, name: 'email', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'first_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'last_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'phone_number', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'birth_date', kind: 'scalar', T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
      { no: 6, name: 'university_id', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 7, name: 'graduation_year', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 8, name: 'picture_file_id', kind: 'scalar', T: 12 /*ScalarType.BYTES*/ },
      { no: 9, name: 'status', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 10, name: 'user_already_verified', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 11, name: 'organization_id', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<RequestInfo>): RequestInfo {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.email = ''
    message.firstName = ''
    message.lastName = ''
    message.phoneNumber = ''
    message.birthDate = 0n
    message.universityId = 0
    message.graduationYear = 0
    message.pictureFileId = new Uint8Array(0)
    message.status = 0
    message.userAlreadyVerified = false
    message.organizationId = 0
    if (value !== undefined) reflectionMergePartial<RequestInfo>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: RequestInfo,
  ): RequestInfo {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* string email */ 1:
          message.email = reader.string()
          break
        case /* string first_name */ 2:
          message.firstName = reader.string()
          break
        case /* string last_name */ 3:
          message.lastName = reader.string()
          break
        case /* string phone_number */ 4:
          message.phoneNumber = reader.string()
          break
        case /* int64 birth_date */ 5:
          message.birthDate = reader.int64().toBigInt()
          break
        case /* int32 university_id */ 6:
          message.universityId = reader.int32()
          break
        case /* int32 graduation_year */ 7:
          message.graduationYear = reader.int32()
          break
        case /* bytes picture_file_id */ 8:
          message.pictureFileId = reader.bytes()
          break
        case /* int32 status */ 9:
          message.status = reader.int32()
          break
        case /* bool user_already_verified */ 10:
          message.userAlreadyVerified = reader.bool()
          break
        case /* int32 organization_id */ 11:
          message.organizationId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: RequestInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* string email = 1; */
    if (message.email !== '') writer.tag(1, WireType.LengthDelimited).string(message.email)
    /* string first_name = 2; */
    if (message.firstName !== '') writer.tag(2, WireType.LengthDelimited).string(message.firstName)
    /* string last_name = 3; */
    if (message.lastName !== '') writer.tag(3, WireType.LengthDelimited).string(message.lastName)
    /* string phone_number = 4; */
    if (message.phoneNumber !== '') writer.tag(4, WireType.LengthDelimited).string(message.phoneNumber)
    /* int64 birth_date = 5; */
    if (message.birthDate !== 0n) writer.tag(5, WireType.Varint).int64(message.birthDate)
    /* int32 university_id = 6; */
    if (message.universityId !== 0) writer.tag(6, WireType.Varint).int32(message.universityId)
    /* int32 graduation_year = 7; */
    if (message.graduationYear !== 0) writer.tag(7, WireType.Varint).int32(message.graduationYear)
    /* bytes picture_file_id = 8; */
    if (message.pictureFileId.length) writer.tag(8, WireType.LengthDelimited).bytes(message.pictureFileId)
    /* int32 status = 9; */
    if (message.status !== 0) writer.tag(9, WireType.Varint).int32(message.status)
    /* bool user_already_verified = 10; */
    if (message.userAlreadyVerified !== false) writer.tag(10, WireType.Varint).bool(message.userAlreadyVerified)
    /* int32 organization_id = 11; */
    if (message.organizationId !== 0) writer.tag(11, WireType.Varint).int32(message.organizationId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.RequestInfo
 */
export const RequestInfo = new RequestInfo$Type()
// @generated message type with reflection information, may provide speed optimized methods
class PendingRequests$Type extends MessageType<PendingRequests> {
  constructor() {
    super('userinfo_service.PendingRequests', [
      { no: 1, name: 'ids', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'requests', kind: 'message', repeat: 1 /*RepeatType.PACKED*/, T: () => Request },
    ])
  }
  create(value?: PartialMessage<PendingRequests>): PendingRequests {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.ids = []
    message.requests = []
    if (value !== undefined) reflectionMergePartial<PendingRequests>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: PendingRequests,
  ): PendingRequests {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated int32 ids */ 1:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.ids.push(reader.int32())
          else message.ids.push(reader.int32())
          break
        case /* repeated userinfo_service.Request requests */ 2:
          message.requests.push(Request.internalBinaryRead(reader, reader.uint32(), options))
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: PendingRequests, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* repeated int32 ids = 1; */
    if (message.ids.length) {
      writer.tag(1, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.ids.length; i++) writer.int32(message.ids[i])
      writer.join()
    }
    /* repeated userinfo_service.Request requests = 2; */
    for (let i = 0; i < message.requests.length; i++)
      Request.internalBinaryWrite(message.requests[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join()
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.PendingRequests
 */
export const PendingRequests = new PendingRequests$Type()
// @generated message type with reflection information, may provide speed optimized methods
class Request$Type extends MessageType<Request> {
  constructor() {
    super('userinfo_service.Request', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'email', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'first_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'last_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'phone_number', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: 'birth_date', kind: 'scalar', T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
      { no: 7, name: 'university_id', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 8, name: 'graduation_year', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 9, name: 'picture_file_id', kind: 'scalar', T: 12 /*ScalarType.BYTES*/ },
      { no: 10, name: 'status', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 11, name: 'user_already_verified', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 12, name: 'organization_id', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<Request>): Request {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.userId = 0
    message.email = ''
    message.firstName = ''
    message.lastName = ''
    message.phoneNumber = ''
    message.birthDate = 0n
    message.universityId = 0
    message.graduationYear = 0
    message.pictureFileId = new Uint8Array(0)
    message.status = 0
    message.userAlreadyVerified = false
    message.organizationId = 0
    if (value !== undefined) reflectionMergePartial<Request>(this, message, value)
    return message
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Request): Request {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 user_id */ 1:
          message.userId = reader.int32()
          break
        case /* string email */ 2:
          message.email = reader.string()
          break
        case /* string first_name */ 3:
          message.firstName = reader.string()
          break
        case /* string last_name */ 4:
          message.lastName = reader.string()
          break
        case /* string phone_number */ 5:
          message.phoneNumber = reader.string()
          break
        case /* int64 birth_date */ 6:
          message.birthDate = reader.int64().toBigInt()
          break
        case /* int32 university_id */ 7:
          message.universityId = reader.int32()
          break
        case /* int32 graduation_year */ 8:
          message.graduationYear = reader.int32()
          break
        case /* bytes picture_file_id */ 9:
          message.pictureFileId = reader.bytes()
          break
        case /* int32 status */ 10:
          message.status = reader.int32()
          break
        case /* bool user_already_verified */ 11:
          message.userAlreadyVerified = reader.bool()
          break
        case /* int32 organization_id */ 12:
          message.organizationId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: Request, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 user_id = 1; */
    if (message.userId !== 0) writer.tag(1, WireType.Varint).int32(message.userId)
    /* string email = 2; */
    if (message.email !== '') writer.tag(2, WireType.LengthDelimited).string(message.email)
    /* string first_name = 3; */
    if (message.firstName !== '') writer.tag(3, WireType.LengthDelimited).string(message.firstName)
    /* string last_name = 4; */
    if (message.lastName !== '') writer.tag(4, WireType.LengthDelimited).string(message.lastName)
    /* string phone_number = 5; */
    if (message.phoneNumber !== '') writer.tag(5, WireType.LengthDelimited).string(message.phoneNumber)
    /* int64 birth_date = 6; */
    if (message.birthDate !== 0n) writer.tag(6, WireType.Varint).int64(message.birthDate)
    /* int32 university_id = 7; */
    if (message.universityId !== 0) writer.tag(7, WireType.Varint).int32(message.universityId)
    /* int32 graduation_year = 8; */
    if (message.graduationYear !== 0) writer.tag(8, WireType.Varint).int32(message.graduationYear)
    /* bytes picture_file_id = 9; */
    if (message.pictureFileId.length) writer.tag(9, WireType.LengthDelimited).bytes(message.pictureFileId)
    /* int32 status = 10; */
    if (message.status !== 0) writer.tag(10, WireType.Varint).int32(message.status)
    /* bool user_already_verified = 11; */
    if (message.userAlreadyVerified !== false) writer.tag(11, WireType.Varint).bool(message.userAlreadyVerified)
    /* int32 organization_id = 12; */
    if (message.organizationId !== 0) writer.tag(12, WireType.Varint).int32(message.organizationId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.Request
 */
export const Request = new Request$Type()
// @generated message type with reflection information, may provide speed optimized methods
class Filters$Type extends MessageType<Filters> {
  constructor() {
    super('userinfo_service.Filters', [{ no: 1, name: 'organization_id', kind: 'scalar', T: 5 /*ScalarType.INT32*/ }])
  }
  create(value?: PartialMessage<Filters>): Filters {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.organizationId = 0
    if (value !== undefined) reflectionMergePartial<Filters>(this, message, value)
    return message
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Filters): Filters {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 organization_id */ 1:
          message.organizationId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: Filters, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 organization_id = 1; */
    if (message.organizationId !== 0) writer.tag(1, WireType.Varint).int32(message.organizationId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message userinfo_service.Filters
 */
export const Filters = new Filters$Type()
/**
 * @generated ServiceType for protobuf service userinfo_service.UserInfoPrivate
 */
export const UserInfoPrivate = new ServiceType('userinfo_service.UserInfoPrivate', [
  { name: 'GetUserIds', options: {}, I: Emails, O: Ids },
  { name: 'CreateUser', options: {}, I: Email, O: Id },
])
/**
 * @generated ServiceType for protobuf service userinfo_service.UserInfoPublic
 */
export const UserInfoPublic = new ServiceType('userinfo_service.UserInfoPublic', [
  { name: 'GetAccountStatuses', options: {}, I: Emails, O: Statuses },
  { name: 'GetUniversities', options: {}, I: Empty, O: Universities },
  { name: 'GetUniversity', options: {}, I: Id, O: University },
  { name: 'GetUser', options: {}, I: UserRequest, O: User },
  { name: 'GetUserIdByEmail', options: {}, I: UserEmailRequest, O: Id },
  { name: 'RequestApproval', options: {}, I: ApprovalRequestEntry, O: Empty },
  { name: 'RequestInformationChange', options: {}, I: InformationChangeEntry, O: Empty },
  { name: 'RevokeRequest', options: {}, I: Empty, O: Empty },
  { name: 'GetRequestInfo', options: {}, I: Empty, O: RequestInfo },
  { name: 'RejectRequest', options: {}, I: Id, O: Empty },
  { name: 'ApproveRequest', options: {}, I: Id, O: Empty },
  { name: 'GetPendingRequests', options: {}, I: Filters, O: PendingRequests },
  { name: 'DeleteAllCurrentUserInformation', options: {}, I: Empty, O: Empty },
  { name: 'EnterOrganization', options: {}, I: InviteCode, O: Empty },
  { name: 'ExitOrganization', options: {}, I: Id, O: Empty },
  { name: 'AddOrganizationMember', options: {}, I: MemberRequest, O: Empty },
  { name: 'RemoveOrganizationMember', options: {}, I: MemberRequest, O: Empty },
  { name: 'GetOrganizationMembers', options: {}, I: Id, O: Users },
  { name: 'GetCurrentUserMemberships', options: {}, I: Empty, O: Ids },
  { name: 'GetOrganizationSpecialities', options: {}, I: Ids, O: UserOrganizationSpecialities },
  { name: 'UpdateOrganizationSpeciality', options: {}, I: UpdateSpecialityRequest, O: Empty },
  { name: 'GetUsers', options: {}, I: UsersRequest, O: Users },
  { name: 'GetUsersOrganizationSpecialities', options: {}, I: UsersSpecialityRequest, O: OrganizationSpecialities },
])
